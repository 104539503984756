import * as React from "react"

import {
    addPropertyControls,
    ControlType,
    ControlDescription,
    PropertyControls,
    RenderTarget,
    withCSS,
} from "framer"

import { addMarkControl } from "https://framer.com/m/Utils-MZAu.js"

import YouTube, { YouTubePlayer } from "react-youtube"

const ComponentName = "Landing Youtube Player"

export interface Props {
    autoplay: boolean
}

const css = ""

let videoElement: YouTubePlayer = null

function BaseComponentWithouCSS<BaseComponentProps>(props) {
    const { autoplay } = props

    const [isPaused, setIsPaused] = React.useState(false)

    const videoRef = React.useRef(null)
    const [videoElement, setVideoElement] = React.useState<YouTubePlayer>(null)
    const [isPlaying, setIsPlaying] = React.useState(autoplay)

    const togglePause = () => {
        setIsPaused(!isPaused)
    }

    React.useEffect(() => {
        if (videoElement) {
            // Pause and Play video
            if (isPaused) {
                videoElement.target.pauseVideo()
            } else {
                videoElement.target.playVideo()
            }
        }
    }, [isPaused, videoElement])

    // Função para alternar entre play e pause
    const togglePlayPause = () => {
        const video = videoRef.current
        if (video) {
            if (video.paused) {
                video.play()
                setIsPlaying(true)
            } else {
                video.pause()
                setIsPlaying(false)
            }
        }
    }

    const _onReady = (event: YouTubePlayer) => {
        setVideoElement(event)
    }

    const opts = {
        height: "390",
        width: "640",
        playerVars: {
            autoplay: 1,
            color: "white",
            controls: 0,
            showinfo: 0,
            rel: 0,
            iv_load_policy: 3,
            modestbranding: 0,
        },
    }

    return (
        <div style={container}>
            <YouTube videoId={"IUN664s7N-c"} opts={opts} onReady={_onReady} />
            <button onClick={togglePause}>Pause</button>
        </div>
    )
}

const container = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

/**
 * @framerPlaceComponent
 */
const BaseComponent: React.ComponentType = withCSS<Props>(
    BaseComponentWithouCSS,
    css
)

BaseComponent.displayName = ComponentName

export default BaseComponent

const defaultProps: Props = {
    autoplay: true,
}

const controlProps: PropertyControls<Props> = {
    autoplay: {
        title: "Auto Play",
        type: ControlType.Boolean,
        defaultValue: defaultProps.autoplay,
    },
}

addPropertyControls(BaseComponent, addMarkControl(controlProps, ComponentName))
